import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import posed, { PoseGroup } from 'react-pose';
import GoogleAnalytics from 'react-ga';

const RouteContainer = posed.div({
	enter: { opacity: 1, delay: 10, beforeChildren: true },
	exit: { opacity: 0 },
});

class Track extends Component {
	componentDidMount = () => {
		const { location } = this.props;
		const page = location.pathname + location.search;
		this.trackPage(page);
	};

	componentDidUpdate = (prevProps) => {
		const { location } = this.props;
		const currentPage = prevProps.location.pathname + prevProps.location.search;
		const nextPage = location.pathname + location.search;
		if (currentPage !== nextPage) {
			this.trackPage(nextPage);
		}
	};

	trackPage = (page) => {
		GoogleAnalytics.set({
			page,
		});
		GoogleAnalytics.pageview(page);
	};

	render() {
		return null;
	}
}

const loading = <LinearProgress />;
const Inicio = React.lazy(() => import('../views/Inicio'));
const Login = React.lazy(() => import('../views/Login'));
const Home = React.lazy(() => import('../views/Home'));
const SesionesDinamica = React.lazy(() => import('../views/SesionesDinamica'));
const Sesiones = React.lazy(() => import('../views/Sesiones'));
const Agenda = React.lazy(() => import('../views/Agenda'));
const Nosotros = React.lazy(() => import('../views/Nosotros'));
const Productos = React.lazy(() => import('../views/Productos'));
const Citas = React.lazy(() => import('../views/Citas'));
const Speakers = React.lazy(() => import('../views/Speakers'));
const StreamingOnLine = React.lazy(() => import('../views/StreamingOnLine'));
const StreamingGrabado = React.lazy(() => import('../views/StreamingGrabado'));
const DescargaTuCertificado = React.lazy(() => import('../views/DescargaTuCertificado'));
const Networking = React.lazy(() => import('../views/Networking'));

export default function App() {
	return (
		<BrowserRouter>
			<Route
				render={({ location }) => {
					return (
						<>
							<PoseGroup>
								<RouteContainer key={location.key || location.pathname}>
									<React.Suspense fallback={loading}>
										<Switch>
											{/* <Route exact path='/' render={(props) => <Inicio {...props} />} /> */}
											<Route exact path='/' render={(props) => <Login {...props} />} />
											<Route exact path='/home' render={(props) => <Home {...props} />} />
											<Route exact path='/espacios/:sesion' render={(props) => <SesionesDinamica {...props} />} />
											<Route exact path='/espacios' render={(props) => <Sesiones {...props} />} />
											<Route exact path='/secciones/agenda' render={(props) => <Agenda {...props} />} />
											<Route exact path='/secciones/nosotros' render={(props) => <Nosotros {...props} />} />
											<Route exact path='/secciones/productos' render={(props) => <Productos {...props} />} />
											<Route exact path='/secciones/citas' render={(props) => <Citas {...props} />} />
											<Route exact path='/secciones/networking' render={(props) => <Networking {...props} />} />
											<Route exact path='/speakers' render={(props) => <Speakers {...props} />} />
											<Route exact path='/streaming/online/:id' render={(props) => <StreamingOnLine {...props} />} />
											<Route exact path='/streaming/:id' render={(props) => <StreamingGrabado {...props} />} />
											<Route exact path='/descarga-tu-certificado' render={(props) => <DescargaTuCertificado {...props} />} />
										</Switch>
									</React.Suspense>
								</RouteContainer>
							</PoseGroup>
							<Track location={location} />
						</>
					);
				}}
			/>
		</BrowserRouter>
	);
}
