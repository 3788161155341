import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import Navigation from './navigation';
import GoogleAnalytics from 'react-ga';
import reportWebVitals from './reportWebVitals';
import WOW from 'wow.js';
import KeysFirebase from './config/firebase.json';
import IdGoogleAnalytics from './config/googleAnalitics.json';
import './assets/scss/main.scss';

firebase.initializeApp(KeysFirebase);
GoogleAnalytics.initialize(IdGoogleAnalytics);

function App() {
	new WOW({
		boxClass: 'wow', // default
		animateClass: 'animated', // default
		offset: 0, // default
		mobile: true, // default
		live: true, // default
	}).init();
	return <Navigation />;
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
